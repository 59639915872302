import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackendService } from '../../core/backend.service';
import { Role, User } from '../../core/user';
import { UserUpdate } from '../user-update.dto';
import { AuthService } from 'src/app/core/auth.service';
import { map } from 'rxjs';

type EditUserDialogData = {
  user: User;
  userUpdate: UserUpdate;
};

function randKeySection() {
  const rand = new Int32Array(1);
  crypto.getRandomValues(rand);
  return Math.abs(rand[0]).toString(36).substring(0, 4).toLocaleUpperCase();
}

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.sass'],
})
export class EditUserDialogComponent {
  allOperationAccessGroups: string[] = [];
  allRoles = Object.values(Role);

  isAdmin$ = this.authService.user$.pipe(
    map((user) => user?.roles.includes(Role.ADMIN)),
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditUserDialogData,
    private authService: AuthService,
    private backendService: BackendService,
  ) {
    data.userUpdate = {};
    this.backendService
      .get<string[]>('/operations/access-groups')
      .subscribe((accessGroups: string[]) => {
        this.allOperationAccessGroups = accessGroups;
      });
  }

  generateKey() {
    const section1 = randKeySection();
    const section2 = randKeySection();
    const section3 = randKeySection();
    const section4 = randKeySection();
    const key = `${section1}-${section2}-${section3}-${section4}`;

    this.data.userUpdate.apiKey = key;
    this.data.user.apiKey = key;
  }
}
