import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { combineLatest, Observable, Subject, firstValueFrom, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  take,
  takeUntil,
} from 'rxjs/operators';
import {
  CompartmentState,
  HazardLightsState,
} from '../../core/robots-service/backend/robot.dto';

import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../core/confirmation-dialog/confirmation-dialog.component';
import { RobotCommunication } from '../../core/robots-service/robot-communication';
import { VideoChannel } from '../../core/robots-service/webrtc/types';
import { identity } from 'ramda';
import { Router } from '@angular/router';
import { AuthService } from '../../../app/core/auth.service';
import { shiftPressed$ } from '../../../utils/shift-pressed';
import { keyPressed } from '../../../utils/keyPressed';
import { SupervisionSettingsService } from '../supervision-settings/supervision-settings.service';
import {
  AttentionDetectionService,
  AttentionSource,
} from '../attention-detection.service';
import {
  HIGH_QUALITY_VIDEO_CONFIG,
  SURROUND_VIEW_VIDEO_CONFIG,
} from '../../../app/core/robots-service/webrtc/video-stream';
import { UserSessionService } from '../../../app/core/user-session/user-session.service';
import { UserSessionEventTrackingService } from '../../core/user-session/user-session-event-tracking.service';
import { UserSessionInteractionEventName } from '../../core/user-session/user-session-interaction-events';
import { UserSessionSystemEventName } from '../../core/user-session/user-session-system-events';
import { FINALIZE_ROBOT_COMMUNICATION_THRESHOLD_MILLIS } from '../../core/robots-service/backend/robot-blocking';
import { SkipRobotDialog } from './skip-robot-dialog.component';

const DROP_ROBOT_AFTER_TAKEOVER_BY_OTHER_OPERATOR_MILLIS = 10 * 1000;

@Component({
  selector: 'app-robot-supervision',
  templateUrl: './robot-supervision.component.html',
  styleUrls: ['./robot-supervision.component.sass'],
  providers: [AttentionDetectionService],
})
export class RobotSupervisionComponent
  implements OnDestroy, OnInit, AfterContentInit, OnChanges
{
  @Input()
  slotIndex?: number;

  @Input()
  robotCommunication!: RobotCommunication;

  _isFocused: boolean = false;

  isMouseHovering = false;

  requireAttention$ = new Subject<boolean>();

  @Input()
  set isFocused(value: boolean) {
    this._isFocused = value;

    if (this._isFocused) {
      this.robotCommunication.sendVideoQualityRequest(
        VideoChannel.Default,
        true,
      );
    } else {
      this.robotCommunication.sendVideoQualityRequest(
        VideoChannel.Default,
        false,
      );
    }
  }

  @Output()
  onFocusChange = new EventEmitter();

  hasRoute$!: Observable<boolean>;

  readonly HAZARD_LIGHTS_STATES = Object.values(HazardLightsState);

  readonly _destroy$ = new Subject<void>();
  readonly _unsubscribe$ = new Subject<void>();

  showSkipRobotForUnsupervisedAutonomyOverlay$: Observable<boolean> = of(false);

  showFullScreenPreview$!: Observable<boolean>;

  isControlledByOtherOperator = false;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly supervisionSettingService: SupervisionSettingsService,
    private readonly dialog: MatDialog,
    private readonly attentionService: AttentionDetectionService,
    private readonly userSessionService: UserSessionService,
    private readonly userInteractionsTrackingService: UserSessionEventTrackingService,
  ) {}

  ngAfterContentInit(): void {
    this.robotCommunication?.enableAutonomy(true);
    this.robotCommunication?.enableManualMouseControl(true);
  }

  isLocked!: boolean;

  async ngOnInit() {
    this.robotCommunication.blockedRobotIsReadyForFinalization$
      .pipe(takeUntil(this._destroy$))
      .subscribe(async (readyToBeFinalized) => {
        try {
          if (readyToBeFinalized) {
            this.userInteractionsTrackingService.trackSystemEvent(
              UserSessionSystemEventName.BLOCKED_ROBOT_UNASSIGNED,
              { robotId: this.robotCommunication.robotId },
            );
            await this.removeRobotSlot();
          }
        } catch (e) {
          console.error('Failed to disable slot', e);
        }
      });
    this.robotCommunication.finalizeBlockedRobotCommunicationInMillis$
      .pipe(
        takeUntil(this._destroy$),
        map(
          (finalizeBlockedRobotCommunicationInMillis) =>
            finalizeBlockedRobotCommunicationInMillis !== null,
        ),
        distinctUntilChanged(),
      )
      .subscribe(async (isRobotBlockingDetected) => {
        if (isRobotBlockingDetected) {
          this.userInteractionsTrackingService.trackSystemEvent(
            UserSessionSystemEventName.ROBOT_BLOCKED,
            { robotId: this.robotCommunication.robotId },
          );
        }
      });

    this.robotCommunication.robotWithUnsupervisedAutonomyAvailableIsReadyForFinalization$
      .pipe(takeUntil(this._destroy$))
      .subscribe(async (readyToBeFinalized) => {
        try {
          if (readyToBeFinalized) {
            this.userInteractionsTrackingService.trackSystemEvent(
              UserSessionSystemEventName.SKIPPED_TO_ACTIVATE_UNSUPERVISED_AUTONOMY,
              { robotId: this.robotCommunication.robotId },
            );
            const robotId = this.robotCommunication.robotId;
            await this.robotCommunication.finalize();
            this.userSessionService.unassignRobots([robotId], true);
          }
        } catch (e) {
          console.error(
            'Failed to skip robot to activate unsupervised autonomy',
            e,
          );
        }
      });

    this.showSkipRobotForUnsupervisedAutonomyOverlay$ =
      this.robotCommunication.finalizeUnsupervisedAutonomyRobotCommunicationInMillis$.pipe(
        takeUntil(this._destroy$),
        map(
          (finalizeBlockedRobotCommunicationInMillis) =>
            finalizeBlockedRobotCommunicationInMillis !== null,
        ),
        distinctUntilChanged(),
      );

    this.showSkipRobotForUnsupervisedAutonomyOverlay$.subscribe(
      async (unsupervisedAutonomyShouldBeActivated) => {
        if (unsupervisedAutonomyShouldBeActivated) {
          this.userInteractionsTrackingService.trackSystemEvent(
            UserSessionSystemEventName.UNSUPERVISED_AUTONOMY_SHOULD_BE_ACTIVATED,
            { robotId: this.robotCommunication.robotId },
          );
        }
      },
    );

    this.robotCommunication.robotState$
      .pipe(takeUntil(this._destroy$), takeUntil(this._unsubscribe$))
      .subscribe((robotState) => {
        this.isLocked = (robotState.compartments ?? []).every(
          (c) => c.state === CompartmentState.CLOSED_AND_LOCKED,
        );
      });

    this.requireAttention$ = this.attentionService.state$;
    const startRobotControl$ = new Subject();

    combineLatest([
      this.robotCommunication.isInControl$,
      this.robotCommunication.controlledBy$,
      this.authService.user$,
    ])
      .pipe(
        takeUntil(this._destroy$),
        takeUntil(this._unsubscribe$),
        filter(() => !this.isControlledByOtherOperator),
      )
      .subscribe({
        next: ([isInControl, controllingUser, user]) => {
          if (isInControl === false) {
            if (controllingUser === undefined) {
              startRobotControl$.next(undefined);
              this.robotCommunication.claimRobotControl(true);
              this.robotCommunication.sendClearPathCorridor();
            } else if (controllingUser?.id !== user?.id) {
              this.isControlledByOtherOperator = true;
              setTimeout(() => {
                this.robotCommunication.finalize();
              }, DROP_ROBOT_AFTER_TAKEOVER_BY_OTHER_OPERATOR_MILLIS);
            }
          }
        },
      });

    this.robotCommunication.connected$
      .pipe(
        takeUntil(this._destroy$),
        takeUntil(this._unsubscribe$),
        filter(identity),
      )
      .subscribe(() => {
        if (!this._isFocused) {
          this.robotCommunication.sendVideoQualityRequest(
            VideoChannel.Default,
            false,
          );
        }
      });

    this.hasRoute$ = this.robotCommunication.robotRoute$.pipe(
      map((route) => !!(route && route.geometry?.length)),
    );

    this.showFullScreenPreview$ = shiftPressed$.pipe(
      map((shiftPressed) => {
        return shiftPressed && this.isMouseHovering;
      }),
    );

    this.showFullScreenPreview$
      .pipe(takeUntil(this._destroy$), takeUntil(this._unsubscribe$))
      .subscribe((fullscreen) => {
        const config = fullscreen
          ? SURROUND_VIEW_VIDEO_CONFIG
          : HIGH_QUALITY_VIDEO_CONFIG;
        this.robotCommunication.sendVideoConfiguration(config);
      });

    keyPressed('KeyS')
      .pipe(
        takeUntil(this._destroy$),
        takeUntil(this._unsubscribe$),
        map((sPressed) => sPressed && this.isMouseHovering),
        filter(identity),
      )
      .subscribe(async () => {
        await this.triggerSnapshot();
      });

    this.robotCommunication.robotState$
      .pipe(takeUntil(this._destroy$), takeUntil(this._unsubscribe$))
      .subscribe({
        next: ({ stopState }) => {
          this.attentionService.request(
            'robot-blocked',
            () => stopState?.isBlocked === true,
          );
        },
        complete: () => {
          this.attentionService.clear('robot-blocked');
        },
      });

    this.robotCommunication.sendLightingCommand(HazardLightsState.AUTO);

    await this.robotCommunication.automaticPowerSaving(false);
    await this.robotCommunication.powerSaving(false);
  }

  async ngOnChanges(changes: SimpleChanges) {
    const robotCommunicationChange = changes['robotCommunication'];
    if (
      robotCommunicationChange?.firstChange === false &&
      robotCommunicationChange?.currentValue !==
        robotCommunicationChange?.previousValue
    ) {
      this.isControlledByOtherOperator = false;
      this._unsubscribe$.next(undefined);
      await this.ngOnInit();
      this.ngAfterContentInit();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next(undefined);
    this.robotCommunication.claimRobotControl(false);
  }

  emitFocusChange(isFocused: boolean) {
    this.onFocusChange.emit(isFocused);
  }

  arrivedAtStop() {
    this.dialog
      .open(ConfirmationDialog, {
        data: {
          message: `Confirm arrival?`,
        },
      })
      .afterClosed()
      .pipe(filter((isConfirmed: boolean) => isConfirmed))
      .subscribe(() => {
        this.robotCommunication.arrivedAtStop();
      });
  }

  onMouseIn() {
    this.robotCommunication.sendVideoQualityRequest(undefined, true);
    this.isMouseHovering = true;
  }

  onMouseOut() {
    this.robotCommunication.sendVideoQualityRequest(undefined, false);
    this.isMouseHovering = false;
  }

  private async removeRobotSlot() {
    const slotIndex = this.slotIndex;
    if (slotIndex !== undefined) {
      this.supervisionSettingService.setRobotSlotsByIndex(false, slotIndex);
    }
    await this.robotCommunication.finalize();
  }

  async clickRemoveRobot() {
    await this.removeRobotSlot();
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.DISABLE_SUPERVISION_SLOT_TRIGGERED,
      {
        robotId: this.robotCommunication.robotId,
      },
    );
  }

  async skipRobot() {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.ROBOT_SKIP_DIALOG_OPEN,
      {
        robotId: this.robotCommunication.robotId,
      },
    );
    const isAskedToPark = await firstValueFrom(
      this.robotCommunication.showParkLowPriorityRobotMessage$,
    );
    this.dialog
      .open(SkipRobotDialog, {
        data: {
          askedToPark: isAskedToPark,
          message:
            'Please, make sure that all robot issues are reported before skipping a robot',
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async ({ skip, reason }) => {
        if (skip) {
          this.userInteractionsTrackingService.trackInteractionEvent(
            UserSessionInteractionEventName.ROBOT_SKIP_TRIGGERED,
            {
              robotId: this.robotCommunication.robotId,
              isAskedToPark,
              skipReason: reason,
            },
          );
          await this.robotCommunication.finalize();
          this.userSessionService.unassignRobots(
            [this.robotCommunication.robotId],
            true,
          );
        }
      });
  }

  goToSupervision(id: string) {
    this.router.navigateByUrl(`/robots/supervise/${id}?active=${id}`);
  }

  async triggerSnapshot() {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.SNAPSHOT_TRIGGER,
      {
        robotId: this.robotCommunication.robotId,
      },
    );
    await this.robotCommunication.triggerSnapshot();
  }

  handleAttentionRequest(source: AttentionSource, needsAttention: boolean) {
    needsAttention
      ? this.attentionService.request(source, () => true)
      : this.attentionService.clear(source);
  }

  snoozeSkipRobotInquiry(countDown: number) {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.KEEP_BLOCKED_ROBOT,
      {
        robotId: this.robotCommunication.robotId,
        reactionTime:
          FINALIZE_ROBOT_COMMUNICATION_THRESHOLD_MILLIS / 1000 - countDown,
      },
    );
    this.robotCommunication?.snoozeSkipRobotInquiry();
  }
}
