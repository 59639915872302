import { RobotIssueSeverity } from '../robot-issues';
import { RobotAudioState } from '../robots-service/audio-control';
import { HazardLightsState } from '../robots-service/backend/robot.dto';
import { VideoChannel } from '../robots-service/webrtc/types';

export enum UserSessionInteractionEventName {
  // Dialog to start end stop calibration is opened
  CALIBRATE_END_STOP_DIALOG_OPENED = 'CalibrateEndStopDialogOpened',
  // End stop calibration is triggered
  CALIBRATE_END_STOP_TRIGGERED = 'CalibrateEndStopTriggered',
  // Dialog to start relocalization is opened
  RELOCALIZE_DIALOG_OPENED = 'RelocalizeDialogOpened',
  // Relocalization is triggered
  RELOCALIZE_TRIGGERED = 'RelocalizeTriggered',
  // Manual driving with mouse is triggered
  MANUAL_DRIVING_WITH_MOUSE = 'ManualDrivingWithMouse',
  // Manual intervention is triggered
  MANUAL_INTERVENTION = 'ManualIntervention',
  // Emergency stop is triggered
  BRAKE_ROBOT = 'BrakeRobot',
  // Enable or disable manual mouse control over robot movement
  ENABLE_MANUAL_MOUSE_CONTROL = 'EnableManualMouseControl',
  // Dialog to file an issue is opened
  ISSUE_DIALOG_OPENED = 'IssueDialogOpened',
  // Issue report failed
  ISSUE_REPORT_FAILED = 'IssueReportFailed',
  // Issue is reported
  ISSUE_REPORTED = 'IssueReported',
  // Snapshot is triggered
  SNAPSHOT_TRIGGER = 'SnapshotTrigger',
  // Dialog control compartment lock is opened
  COMPARTMENT_DIALOG_OPENED = 'CompartmentDialogOpened',
  // Compartment lock status is updated
  COMPARTMENT_LOCK_STATUS_UPDATE = 'CompartmentLockStatusUpdate',
  // Hazard light mode is toggled
  TOGGLE_HAZARD_LIGHT_MODE = 'ToggleHazardLightMode',
  // Siren is toggled
  TOGGLE_SIREN = 'ToggleSiren',
  // High beams are toggled
  TOGGLE_HIGH_BEAMS = 'ToggleHighBeams',
  // Automatic power saving is toggled
  TOGGLE_AUTOMATIC_POWER_SAVING = 'ToggleAutomaticPowerSaving',
  // Audio state is toggled
  TOGGLE_AUDIO_STATE = 'ToggleAudioState',
  // Microphone is enabled
  ENABLED_MICROPHONE = 'EnabledMicrophone',
  // Power saving is toggled
  TOGGLE_POWER_SAVING = 'TogglePowerSaving',
  // Dialog to confirm arrival at stop is opened
  ARRIVED_AT_STOP_DIALOG_OPENED = 'ArrivedAtStopDialogOpened',
  // Arrival at stop is triggered
  ARRIVED_AT_STOP_TRIGGERED = 'ArrivedAtStopTriggered',
  // Overlay map is toggled
  TOGGLE_OVERLAY_MAP = 'ToggleOverlayMap',
  // Autonomy enabled state is toggled
  TOGGLE_IS_AUTONOMY_ENABLED = 'ToggleIsAutonomyEnabled',
  // Obstacle is overridden
  OVERRIDE_OBSTACLE = 'OverrideObstacle',
  // Video channel is updated
  VIDEO_CHANNEL_UPDATE = 'VideoChannelUpdate',
  // Zoom control is enabled
  ZOOM_ENABLED = 'ZoomEnabled',
  // Confirm route corridor e.g., crossing is confirmed
  CONFIRM_ROUTE_CORRIDOR = 'ConfirmRouteCorridor',
  // Revoke route corridor e.g., crossing is revoked
  REVOKE_ROUTE_CORRIDOR = 'RevokeRouteCorridor',
  // Attention status is hidden
  HIDE_ATTENTION_STATUS = 'HideAttentionStatus',
  // Deadman switch button is clicked
  DEADMAN_SWITCH_BUTTON_CLICKED = 'DeadmanSwitchButtonClicked',
  // Keep a robot which is blocked for too long
  KEEP_BLOCKED_ROBOT = 'KeepBlockedRobot',
  // User changed configuration of robot slots
  SUPERVISION_ROBOT_SLOT_CHANGED = 'SupervisionRobotSlotChanged',
  // Network interface is selected
  SELECT_NETWORK_INTERFACE = 'SelectNetworkInterface',
  // Robot is ready for orders
  SET_READY_FOR_ORDERS = 'SetReadyForOrders',
  // Curb climbing mode is enabled
  ENABLE_CURB_CLIMBING_MODE = 'EnableCurbClimbingMode',
  // Dialog to skip robot is opened
  ROBOT_SKIP_DIALOG_OPEN = 'RobotSkipDialogOpen',
  // Skip robot is triggered
  ROBOT_SKIP_TRIGGERED = 'RobotSkipTriggered',
  DISABLE_SUPERVISION_SLOT_TRIGGERED = 'DisableSupervisionSlotTriggered',
  // Remote power circle is triggered
  REMOTE_POWER_CIRCLE = 'RemotePowerCircle',
  // Start data collection
  DATA_COLLECTION_TRIGGERED = 'DataCollectionTriggered',
  REPORT_BLOCKAGE_DIALOG_OPENED = 'ReportBlockageDialogOpened',
}

export type UserSessionInteractionEventPayload = {
  [UserSessionInteractionEventName.CALIBRATE_END_STOP_DIALOG_OPENED]: {
    robotId?: string;
  };
  [UserSessionInteractionEventName.CALIBRATE_END_STOP_TRIGGERED]: {
    robotId?: string;
  };
  [UserSessionInteractionEventName.ENABLE_MANUAL_MOUSE_CONTROL]: {
    robotId?: string;
    // Enable or disable manual mouse control over robot movement
    isManualMouseControlEnabled: boolean;
  };
  [UserSessionInteractionEventName.DATA_COLLECTION_TRIGGERED]: {
    robotId?: string;
  };
  [UserSessionInteractionEventName.MANUAL_DRIVING_WITH_MOUSE]: {
    robotId?: string;
    // Acceleration of a robot
    acceleration: number;
    // Turn of a robot
    turn: number;
  };
  [UserSessionInteractionEventName.MANUAL_INTERVENTION]: {
    robotId?: string;
  };
  [UserSessionInteractionEventName.RELOCALIZE_DIALOG_OPENED]: {
    robotId?: string;
  };
  [UserSessionInteractionEventName.RELOCALIZE_TRIGGERED]: {
    robotId?: string;
  };
  [UserSessionInteractionEventName.ISSUE_DIALOG_OPENED]: {
    robotId?: string;
    // tbd;
  };
  [UserSessionInteractionEventName.ISSUE_REPORT_FAILED]: {
    robotId?: string;
    // Error code
    code: number;
    // Error message
    message: string;
  };
  [UserSessionInteractionEventName.ISSUE_REPORTED]: {
    robotId?: string;
    // Issue ID
    issueId: string;
    // Issue custom ID
    customId: string;
    // Issue name
    name: string;
    // Issue severity level
    severity?: RobotIssueSeverity;
  };
  [UserSessionInteractionEventName.COMPARTMENT_DIALOG_OPENED]: {
    robotId?: string;
  };

  [UserSessionInteractionEventName.REPORT_BLOCKAGE_DIALOG_OPENED]: {
    robotId?: string;
  };
  [UserSessionInteractionEventName.TOGGLE_HAZARD_LIGHT_MODE]: {
    robotId?: string;
    // New state of hazard lights
    hazardLightState: HazardLightsState;
  };
  // [UserSessionInteractionEventName.LID_STATUS_UPDATE]: {
  //
  //   robotId?: string;
  //   // New state of lid lock
  //   isLocked: boolean;
  // };
  [UserSessionInteractionEventName.TOGGLE_SIREN]: {
    robotId?: string;
    // New state of siren
    sirenOn: boolean;
  };
  [UserSessionInteractionEventName.TOGGLE_HIGH_BEAMS]: {
    robotId?: string;
    // New state of high beams
    highBeamsOn: boolean;
  };
  [UserSessionInteractionEventName.TOGGLE_AUTOMATIC_POWER_SAVING]: {
    robotId?: string;
    // New state of automatic power saving
    automaticPowerSavingOn: boolean;
  };
  [UserSessionInteractionEventName.TOGGLE_AUDIO_STATE]: {
    robotId?: string;
    // New state of audio
    audioState: RobotAudioState;
  };
  [UserSessionInteractionEventName.ENABLED_MICROPHONE]: {
    robotId?: string;
    // New state of microphone
    microphoneOn: boolean;
  };
  [UserSessionInteractionEventName.TOGGLE_POWER_SAVING]: {
    robotId?: string;
    // New state of power saving
    powerSavingOn: boolean;
  };
  [UserSessionInteractionEventName.ARRIVED_AT_STOP_DIALOG_OPENED]: {
    robotId?: string;
  };
  [UserSessionInteractionEventName.ARRIVED_AT_STOP_TRIGGERED]: {
    robotId?: string;
  };
  [UserSessionInteractionEventName.TOGGLE_OVERLAY_MAP]: {
    robotId?: string;
    // Show or hide overlay map
    overlayMapEnabled: boolean;
  };
  [UserSessionInteractionEventName.TOGGLE_IS_AUTONOMY_ENABLED]: {
    robotId?: string;
    // Enable or disable autonomy
    isAutonomyEnabled: boolean;
  };
  [UserSessionInteractionEventName.OVERRIDE_OBSTACLE]: {
    robotId?: string;
  };
  [UserSessionInteractionEventName.BRAKE_ROBOT]: {
    robotId?: string;
  };
  [UserSessionInteractionEventName.CONFIRM_ROUTE_CORRIDOR]: {
    robotId?: string;
    // Time it took an operator to confirm the corridor in seconds
    confirmedAfterSeconds: number;
    // Distance to corridor in meters
    distanceToCorridor: number;
  };
  [UserSessionInteractionEventName.REVOKE_ROUTE_CORRIDOR]: {
    robotId?: string;
    // Edge ID of corridor to revoke
    edgeId: number;
  };
  [UserSessionInteractionEventName.HIDE_ATTENTION_STATUS]: {
    robotId?: string;
    // An attention status to hide
    attentionStatus: string;
  };
  [UserSessionInteractionEventName.DEADMAN_SWITCH_BUTTON_CLICKED]: {
    // Time it took an operator to react to deadman switch in seconds
    reactionTime: number;
  };
  [UserSessionInteractionEventName.SUPERVISION_ROBOT_SLOT_CHANGED]: {
    robotId?: string;
    // Number of robot slots enabled
    slotCount: number;
  };
  [UserSessionInteractionEventName.SELECT_NETWORK_INTERFACE]: {
    robotId?: string;
    // Newly selected network interface
    networkInterface: string;
  };
  [UserSessionInteractionEventName.SET_READY_FOR_ORDERS]: {
    robotId?: string;
    // Set robot ready for orders state enabled or disabled
    readyForOrders: boolean;
  };
  [UserSessionInteractionEventName.ENABLE_CURB_CLIMBING_MODE]: {
    robotId?: string;
    // Enable or disable curb climbing mode
    isCurbClimbing: boolean;
  };
  [UserSessionInteractionEventName.ROBOT_SKIP_DIALOG_OPEN]: {
    robotId?: string;
  };
  [UserSessionInteractionEventName.ROBOT_SKIP_TRIGGERED]: {
    robotId?: string;
    isAskedToPark?: boolean;
    skipReason?: string;
  };
  [UserSessionInteractionEventName.DISABLE_SUPERVISION_SLOT_TRIGGERED]: {
    robotId?: string;
  };
  [UserSessionInteractionEventName.REMOTE_POWER_CIRCLE]: {
    robotId?: string;
  };
  [UserSessionInteractionEventName.SNAPSHOT_TRIGGER]: {
    robotId?: string;
  };
  [UserSessionInteractionEventName.VIDEO_CHANNEL_UPDATE]: {
    robotId?: string;
    // New video channel
    videoChannel: VideoChannel;
  };
  [UserSessionInteractionEventName.ZOOM_ENABLED]: {
    robotId?: string;
    // X screen coordinates of the zoom region
    x: number;
    // Y screen coordinates of the zoom region
    y: number;
  };
  [UserSessionInteractionEventName.KEEP_BLOCKED_ROBOT]: {
    robotId?: string;
    // Time it took an operator to keep a robot blocked in seconds
    reactionTime: number;
  };
  [UserSessionInteractionEventName.COMPARTMENT_LOCK_STATUS_UPDATE]: {
    robotId?: string;
    // New state of compartment lock
    isLocked: boolean;
    // Compartment ID
    compartmentId?: string;
  };
};

export type UserSessionInteractionEvent<
  Type extends UserSessionInteractionEventName,
> = {
  type: Type;
  payload?: UserSessionInteractionEventPayload[Type];
};
