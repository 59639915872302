import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmationDialogData {
  message: string;
  askedToPark?: boolean;
}

export type SkipRobotDialogResult = {
  skip: boolean;
  reason: string;
};

@Component({
  selector: 'app-skip-robot-dialog',
  templateUrl: './skip-robot-dialog.component.html',
  styleUrls: ['./skip-robot-dialog.component.sass'],
})
export class SkipRobotDialog {
  message: string;
  reason: string = '';

  constructor(
    public dialogRef: MatDialogRef<
      ConfirmationDialogData,
      SkipRobotDialogResult
    >,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
  ) {
    this.message = data.message;
  }

  onConfirmClick() {
    this.dialogRef.close({ skip: true, reason: this.reason });
  }

  onCancelClick() {
    this.dialogRef.close({ skip: false, reason: '' });
  }
}
